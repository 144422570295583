import request from "@/request/request";


//根据公司id获取公司信息
export const getCompanyInfo = function(id){
    return request.get("/web/company/view", {params: {companyId: id}})
}

//根据条件获取公司列表，data中参数为name,addr,type
export const getCompanyList = function (data) {
    return request.post("/web/company/list",data)
}

//根据条件获取职位列表 data中传递，name,addr,salaryRange,serviceYear四个参数
export const getPositionList = function (data) {
    return request.post("/web/needPosition/list", data)
}

// 获取职位详细信息
export const getPositionById  = function (id) {
    return request.get("/web/needPosition/view", {params: {needPositionId: id}})
}

//获取职位信息列表,传一个数组
export const positionList = (data) => {
    return request.post("/web/position/list", {
        "keyWordList": data
    })
}

//根据公司查询职位
export const positionListByCompany = (id) => {
    return request.get("/web/company/companyAboutNeedPosition", {params: {companyId: id}})
}

// 获取职位二维码
export const getPositionCode = (id) => {
    return request.get("/recruit/need/position/getMiniAppQRCode", {params: {positionId: id}})
}


