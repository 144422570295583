import { createRouter, createWebHashHistory } from "vue-router";
import LayoutView from "../views/layout/LayoutView.vue";
import HomeView from "../views/home/HomeView.vue";
import WorkView from "../views/work/WorkView.vue";
import CampusRecruitment from "../views/campus/campusRecruitment.vue";
import Admission from "../views/admission/admissionTest.vue";
import Company from "../views/company/CompanyView.vue";
import CompanyDetails from "../views/companyDetails/companyDetails";
import WorkDetails from "../views/workDetails/workDeatils";
import SearchPosition from "../views/searchPosition/SearchPosition";
import SearchCompany from "../views/searchCompany/SearchCompany";
import ShowStatement from "../views/statement/showStatement"

const routes = [
	{
		path: "/",
		name: "layout",
		component: LayoutView,
		children: [
			{
				path: "/",
				redirect: "/home",
			},
			{
				path: "/home",
				name: "home",
				component: HomeView,
			},
			{
				path: "/work",
				name: "work",
				component: WorkView,
			},
			{
				path: "/company",
				name: "company",
				component: Company,
			},
			{
				path: "/campus",
				name: "campus",
				component: CampusRecruitment,
			},
			{
				path: "/admission",
				name: "admission",
				component: Admission,
			}
		],
	},
	{
		path: "/companyDetails",
		name: "companyDetails",
		component: CompanyDetails,
	},
	{
		path: "/workDetails",
		name: "workDetails",
		component: WorkDetails,
	},
	{
		path: "/searchPosition",
		name: "searchPosition",
		component: SearchPosition,
	},
	{
		path: "/searchCompany",
		name: "searchCompany",
		component: SearchCompany,
	},
	{
		path: "/showStatement",
		name: "showStatement",
		component: ShowStatement,
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
