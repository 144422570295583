import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "loading"
};
import { onMounted, onBeforeUnmount, onUpdated } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'App',
  setup(__props) {
    const router = useRouter();
    const handleResize = () => {
      if (window.innerWidth > 913) {
        window.location.href = "https://youmaijob.com";
      }
    };
    onMounted(() => {
      if (router.path !== "/home") {
        router.push("/home");
      }
      window.addEventListener('resize', handleResize);
      handleResize();
    });
    onUpdated(() => {
      window.addEventListener('resize', handleResize);
      handleResize();
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_van_loading = _resolveComponent("van-loading");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view), _ctx.$store.state.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_loading, {
        size: "30px",
        color: "#4C80CC"
      }, {
        default: _withCtx(() => [_createTextVNode("数据加载中...")]),
        _: 1
      })])) : _createCommentVNode("", true)], 64);
    };
  }
};