import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/image/honer1.jpg';
import _imports_1 from '@/assets/image/honer2.jpg';
import _imports_2 from '@/assets/image/honer3.jpg';
const _withScopeId = n => (_pushScopeId("data-v-a1c697aa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "showStatement"
};
const _hoisted_2 = {
  class: "image"
};
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
const _hoisted_4 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
const _hoisted_5 = {
  key: 2,
  src: _imports_2,
  alt: ""
};
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
export default {
  __name: 'showStatement',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    const onClickLeft = () => history.back();
    const backHome = () => {
      router.push("/home");
    };
    // console.log(route.query.id)

    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("nav", null, [_createVNode(_component_van_nav_bar, {
        title: "公司荣誉",
        "left-text": "返回",
        "left-arrow": "",
        onClickLeft: onClickLeft
      }, {
        right: _withCtx(() => [_createVNode(_component_van_icon, {
          style: {
            "color": "#1989FA"
          },
          class: "iconfont",
          "class-prefix": "icon",
          name: "shouye",
          size: "18",
          onClick: backHome
        })]),
        _: 1
      })]), _createElementVNode("div", _hoisted_2, [_unref(route).query.id === '0' ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : _createCommentVNode("", true), _unref(route).query.id === '1' ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : _createCommentVNode("", true), _unref(route).query.id === '2' ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : _createCommentVNode("", true)])]);
    };
  }
};