import { createStore } from "vuex";

export default createStore({
	state: {
		pathActive: '/home',
		homeInputValue: '',
		searchCategory: '职位',
		choosePosition: ['不限', '不限', '不限', '不限'],
		chooseCompany: ['不限', '不限', '不限'],
		menuPitch: '/about',
		positionSelectData: {salaryRange: ["1", "20"]},
		companySelectData: {type: 2},
		positionInputValue: '',
		isLoading: false,
		searchPosition: false,
		searchCompany: false
	},
	getters: {},
	mutations: {},
	actions: {},
	modules: {},
});
